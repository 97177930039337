import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  DashboardOutlined,
  UserOutlined,
  UploadOutlined,
  PieChartOutlined,
  BarChartOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;

const Sidebar = () => {
  return (
    <Menu mode="inline" style={{ height: "100%", borderRight: 0 }}>
      <Menu.Item key="1" icon={<DashboardOutlined />}>
        <Link to="/dashboard">Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        <Link to="/debtors">Debtors</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<UploadOutlined />}>
        <Link to="/loans">Loans</Link>
      </Menu.Item>
      <SubMenu key="sub1" icon={<PieChartOutlined />} title="Reports">
        <Menu.Item key="4" icon={<BarChartOutlined />}>
          <Link to="/reports/monthly">Monthly Reports</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<FileTextOutlined />}>
          <Link to="/transactions">Transactions</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default Sidebar;
