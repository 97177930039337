import React from "react";
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <Header
      className="header"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 20px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src="/logo.png"
          alt="Logo"
          style={{ marginRight: "10px", height: "38px" }}
        />
        {/* <h2 style={{ margin: 0, color: "#000" }}>Edis Credit Loan Management System</h2> */}
      </div>
      <Menu
        theme="light"
        mode="horizontal"
        style={{ flex: 1, justifyContent: "flex-end" }}
      >
        <Menu.Item key="1" onClick={handleLogout}>
          Logout
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default Navbar;
